import React, { useState, useEffect } from 'react';
import '../css/Navbar.css';

import logo from '../images/logo_dar_simbol.svg';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  if (props.hiddenOnRoutes.includes(location.pathname)) {
      return <></>;
  } else {
    return (
      <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
        <div className="navbar-brand">
          <a href="/">
            <img src={logo} className='logo' alt="Logo" />
          </a>
          <div className="hamburger" onClick={handleMenuToggle}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul className="navbar-menu">
        <li className="nav-item">
            <Link className="nav-link" to="/">Acasă</Link>
          </li>
          <li className="nav-item">
            <HashLink className="nav-link" to='/#information'>Informații</HashLink>
          </li>
          <li className="nav-item">
            <HashLink className="nav-link" to="/#team">Echipă</HashLink>
          </li>
          <li className="nav-item">
            <HashLink className="nav-link" to="/#contact">Contact</HashLink>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/galerie">Galerie</Link>
          </li>
        </ul>
      </nav>
    );
  }
}