import text from '../assets/text.json'
import AnimateIn from '../components/AnimateIn'

export default function Info() {
    return (

        <div id="information" className='text-block'>
            <AnimateIn duration={0.8}>
                <h1 id='title'>Informații</h1>
                <p id='content'>Asociația noastră ajută la obținerea despăgubirilor din asigurări în urma accidentelor rutiere prin parcurgerea unui proces legal și administrativ. Iată câțiva pași cheie pe care îi urmează în general asociațiile pentru a obține despăgubirile pentru membrii lor:</p>
                <ol id='info-steps'>
                    {text.home.information.steps.map((step, i) => <li key={i}><strong>{step.title}</strong>{step.content}</li>)}
                </ol>
            </AnimateIn>
        </div>
    )
}