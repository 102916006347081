import React from 'react';
import '../css/footer.css';
import text from '../assets/text.json'
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

export default function Footer(props) {
  const location = useLocation();

  if (props.hiddenOnRoutes.includes(location.pathname)) {
    return <></>;
  } else {
    return (
      <>
        <footer>
          <div id="lower-footer"></div>
          <div className='flex-row' >
            <div className='flex-column' >
              <a className='footer-title'>DAR</a>
              <p className='footer-item' id='footer-description'>
                {text.footer.description}
              </p>
            </div>
            <div className='flex-column'>
              <a className='footer-title'>Scurtături</a>
              <div className='flex-column footer-item'>
                <HashLink className="nav-link" to="/#">Acasă</HashLink>
                <HashLink className="nav-link" to='/#information'>Informații</HashLink>
                <HashLink className="nav-link" to="/#team">Echipă</HashLink>
                <HashLink className="nav-link" to="/#contact">Contact</HashLink>
                <Link className="nav-link" to="/galerie">Galerie</Link>
              </div>
            </div>
            <div className='flex-column'>
              <a className='footer-title'>Contact</a>
              <div className='flex-column footer-item'>
                <a href='https://www.google.com/maps?q=Strada+Vaselor+nr.+16+sector+2,+București'>{text.contact.address}</a>
                <a href={'mailto:' + text.contact.email}>{text.contact.email}</a>
                <a href={'tel:' + text.contact.phone}>{text.contact.phone}</a>
              </div>
            </div>
          </div>
          <div id='credits'>Dar © 2023 Written by <b>Frann </b> & <b >SorinXV</b></div>
        </footer>
      </>
    );
  }


}
