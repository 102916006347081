import '../css/echipa.css';
import bogdan from '../images/echipa/bogdan.webp'
import anamaria from '../images/echipa/stoiananamaria.webp'
import corina from '../images/echipa/corina.webp'
import text from '../assets/text.json';
import AnimateIn from '../components/AnimateIn';
export default function Echipa() {
    const teamMembers = [
        {
            id: '1',
            nume: 'Stoian Anamaria',
            pozitie: 'Președinte',
            imagine: anamaria,
        },
        {
            id: '2',
            nume: 'Bogdan Marinescu',
            pozitie: 'Vicepreședinte',
            imagine: bogdan,
        },
        {
            id: '3',
            nume: 'Corina Marinescu ',
            pozitie: 'Vicepreședinte',
            imagine: corina,
        }
    ];
    return (
        <>

            <div id='echipa'>
                <AnimateIn duration={0.4}>
                    <div id="inner-team">
                        <h1 className='titlu fade-in'>Echipa DAR</h1>
                        <p className='paragraph fade-in'>{text.team.description}</p>
                    </div>
                </AnimateIn>

                <div className="card-container">
                    {teamMembers.map((member, i) => (
                        <AnimateIn duration={1.2 + i * 0.150}>
                            <div className="fade-in card" key={member.id} style={{ flexDirection: member.id % 2 !== 0 ? 'row' : 'row-reverse' }}>
                                <img className="imagine-membru" src={member.imagine} alt={member.nume} />
                                <div className="member-info">
                                    <h2 className="nume-membru">{member.nume}</h2>
                                    <p className="pozitie-membru" style={{ textAlign: member.id % 2 !== 0 ? 'left' : 'right' }}>{member.pozitie}</p>
                                </div>

                            </div>
                        </AnimateIn>

                    ))}
                </div>
            </div>
        </>
    );
}
