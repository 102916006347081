import text from '../assets/text.json';
import AnimateSide from '../components/AnimateSide';

export default function Contact() {
    return (
        <>
            <h1 className="title">{text.contact.title}</h1>
            <p className="content">
                Suntem aici pentru a te ajuta în cazul în care ai fost implicat într-un accident rutier și ai nevoie de asistență juridică.<br></br>
                Pentru întrebări, programări sau orice altă informație, te rugăm să ne contactezi folosind detaliile de mai jos:<br></br><br></br>

                <AnimateSide duration={0.8}>
                    <strong>Adresa</strong>: Strada Vaselor nr. 16 sector 2, București<br></br>
                    <strong>Telefon</strong>: +40 785-365-129<br></br>
                    <strong>E-mail</strong>: asociatiadar@indrumari-juridice.eu<br></br><br></br>
                </AnimateSide>


                Echipa noastră de experți în dreptul daunelor personale și accidente rutiere este pregătită să-ți ofere consultanță și să te ghideze prin procesul de obținere a despăgubirilor pe care le meriți.<br></br>
                Nu ezita să ne contactezi pentru a primi asistență de încredere în soluționarea cazului tău.<br></br>

                Vă rugăm să rețineți că informațiile furnizate prin intermediul formularului de contact sau a adresei de e-mail sunt confidențiale și vor fi tratate cu cea mai mare atenție.
            </p>
        </>
    )
}