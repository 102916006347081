import React from 'react'
import '../css/partners.css';
import { Link } from 'react-router-dom';
export default function Partners() {

  return (
    <div className='partners-container'>
        <div className='half-screen' id='split-dar'>
            <Link className='overlay' to={"/"}></Link>
            <img src='/partners/logo_dar.png'></img>
        </div>
        <div className='half-screen' id='split-cuculis'>
            <a className='overlay' href='https://indrumari-juridice.eu/'></a>
            <img src='/partners/cuculis.webp' id='cuculis-img'></img>
        </div>
    </div>
  )
}
