
import '../css/home.css';
import hero from '../images/image-11.webp'
import text from '../assets/text.json'
import Echipa from './Echipa';
import Info from './Info';
import Contact from './Contact';
import '../animations/animations.css';
import AnimateIn from '../components/AnimateIn';

export default function Home() {
  return (
    <>
      <div className='home-container'>
        <img src={hero} id="hero-image" alt='Echipa DAR'></img>
        <div className='inner-hero'>
          <h1 className='word-blur-anim-parent'>
            <span className='word-blur-anim'>DE </span>
            <span className='word-blur-anim'>LA </span>
            <span className='word-blur-anim'>ACCIDENT </span>
            <span className='word-blur-anim'>LA </span>
            <span className='word-blur-anim'>DESPĂGUBIRE, </span>
            <span className='word-blur-anim'>SUNTEM </span>
            <span className='word-blur-anim'>ALĂTURI </span>
            <span className='word-blur-anim'>DE </span>
            <span className='word-blur-anim'>TINE </span>
          </h1>
        </div>
      </div>

      <div className='container'>
        <div id='introduction' className='text-block'>
          <AnimateIn duration={0.8}><h1 id='title'>{text.home.introduction.title}</h1></AnimateIn>
          <AnimateIn duration={0.8}><p id='content'>{text.home.introduction.content}</p></AnimateIn>
        </div>
      </div>
      <div className='container'>
        <Info></Info>
      </div>
      <div className='container' id="team">
        <div className='text-block'>
          <Echipa></Echipa>
        </div>
      </div>
      <div className='container' id="contact">
        <div className='text-block'>
          <Contact></Contact>
        </div>
      </div>
    </>
  );
}
