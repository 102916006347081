import React, { useState, useEffect } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
/**
 * @param duration Animation duration 
 */
export default function AnimateIn(props) {
  return <ScrollAnimation
    animateIn="fadeAndMove" duration={props.duration}>
    {props.children}
  </ScrollAnimation >
}
