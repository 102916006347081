import '../css/gallery.css'
export default function Galerie() {
  return (
    <>
      <h1 className='title container'>Inceputuri - 05/23</h1>
      <div className="container gallery">
        <div className="gallery-item">
          <img src="../photos/image3.webp" alt="Image 3" />
        </div>
        <div className="gallery-item">
          <img src="../photos/image5.webp" alt="Image 5" />
        </div>
        <div className="gallery-item">
          <img src="../photos/image6.webp" alt="Image 6" />
        </div>
        <div className="gallery-item">
          <img src="../photos/image7.webp" alt="Image 7" />
        </div>
        <div className="gallery-item">
          <img src="../photos/image8.webp" alt="Image 8" />
        </div>
        <div className="gallery-item">
          <img src="../photos/image11.webp" alt="Image 10" />
        </div>

      </div>
    </>
  );
}