import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import Galerie from "./pages/Gallery.js";
import "./css/index.css";
import Home from "./pages/Home.js";
import Partners from "./pages/Partners.js";

export default function App() {

  const hiddenOnRoutes = ['/partners/'];

  return (
    <>
      <Router>
        <Navbar hiddenOnRoutes={hiddenOnRoutes} />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/galerie" element={<Galerie />} />
            <Route path="/partners" element={<Partners />}></Route>
          </Routes>
        <Footer hiddenOnRoutes={hiddenOnRoutes} />
      </Router>
      
    </>
  );
}